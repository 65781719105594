import React from 'react';
import { Link } from 'gatsby';

import Seo from 'components/shared/Seo';

export default ({ pageContext: { allPokemon } }) => (
  <>
    <Seo title="Pokemons" />
    <div style={{ width: 960, margin: '4rem auto' }}>
      <h1>Choose a Pokémon!</h1>
      <ul style={{ padding: 0 }}>
        {allPokemon.map((pokemon) => (
          <li
            key={pokemon.id}
            style={{
              textAlign: 'center',
              listStyle: 'none',
              display: 'inline-block',
            }}
          >
            <Link to={`/pokemon/${pokemon.name}`}>
              <p>{pokemon.name}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </>
);
